exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-use-cases-care-services-js": () => import("./../../../src/pages/use-cases/care-services.js" /* webpackChunkName: "component---src-pages-use-cases-care-services-js" */),
  "component---src-pages-use-cases-customers-and-staff-js": () => import("./../../../src/pages/use-cases/customers-and-staff.js" /* webpackChunkName: "component---src-pages-use-cases-customers-and-staff-js" */),
  "component---src-pages-use-cases-education-js": () => import("./../../../src/pages/use-cases/education.js" /* webpackChunkName: "component---src-pages-use-cases-education-js" */),
  "component---src-pages-use-cases-mental-health-js": () => import("./../../../src/pages/use-cases/mental-health.js" /* webpackChunkName: "component---src-pages-use-cases-mental-health-js" */),
  "component---src-pages-use-cases-risk-assessments-js": () => import("./../../../src/pages/use-cases/risk-assessments.js" /* webpackChunkName: "component---src-pages-use-cases-risk-assessments-js" */)
}

